<template>
  <div class="order">
    <titleNav title="订单详情" :left-arrow="true" left-text="" />
    <div class="topbacc">
      <div>{{ datas.name }}</div>
      <div>身份证号：{{ datas.idcardno }}</div>
      <div>手机号：{{ datas.phone }}</div>
    </div>
    <div class="blzt">
      <div>职业技能办理状态</div>
      <div>暂无办理信息</div>
    </div>
    <div class="shadow"></div>
    <div class="record">
      <!-- 可以支付取消注释 -->
      <!-- <div>服务付款记录</div> -->
      <div class="fuwu">服务记录</div>
      <!-- <div v-if="datas.status == 1">
        <div></div>
        未支付
      </div>
      <div v-if="datas.status == 2">
        <div></div>
        {{ datas.pay_time }}({{datas.verify_status==0?'未审核':(datas.verify_status==1?'通过':'驳回(可退款)')}})
      </div>
      <div v-if="datas.status == 4">
        <div></div>
        已退款
      </div> -->
      <button @click="details(datas.orderid)">查看详情</button>
    </div>
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
// import { Button, Dialog, Icon, Toast, NavBar, Swipe, SwipeItem } from "vant";
import { getorderinfo } from "@/network/index.js";
export default {
  components: {
    titleNav,
  },
  data() {
    return {
      datas: {},
    };
  },
  mounted() {
    this.list();
  },
  methods: {
    list() {
      let userid = sessionStorage.getItem("id");
      let data = {
        userid,
        orderid: this.$route.query.id,
      };
      getorderinfo(data).then((res) => {
        console.log(res)
        this.datas = res.data.data;
      });
    },
    details(e) {
      let id = e;
      this.$router.push("/orderfys?orderid=" + e);
    },
  },
};
</script>

<style scoped lang="scss">
.order {
  .topbacc {
    padding-left: 4vw;
    padding-top: 15px;
    width: 100%;
    height: 150px;
    background-color: #0f81fd;
    color: #ffffff;
    font-size: 20px;
    line-height: 30px;
  }
  .blzt {
    width: 92vw;
    margin-left: 4vw;
    background-color: #ffffff;
    margin-top: -40px;
    border-radius: 6px;
    div:nth-child(1) {
      padding: 0 4vw;
      padding-top: 15px;
      font-size: 22px;
      font-weight: 700;
    }
    div:nth-child(2) {
      padding: 15px 4vw;
      font-size: 20px;
    }
  }
  .shadow {
    width: 100%;
    height: 1px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.9);
  }
  .record {
    margin-top: 25px;
    margin-left: 4vw;
    font-size: 22px;
    font-weight: 700;
    div:nth-child(2) {
      margin-top: 15px;
      float: left;
      font-size: 20px;
      font-weight: 500;
      div {
        width: 10px;
        height: 10px;
        background-color: rgb(254, 164, 1);
        border-radius: 50%;
        display: inline-block;
      }
    }
    .fuwu{
      display: inline-block;
    }
    button {
      // margin-top: 15px;
      margin-right: 4vw;
      font-size: 18px;
      background-color: #0f81fd;
      color: #ffffff;
      border: 0;
      width: 100px;
      height: 30px;
      border-radius: 15px;
      float: right;
    }
  }
}
</style>